import React, { useCallback, useEffect } from "react"
import { useState } from "react"
import styled from "styled-components"
import { colors } from "../styles/colors"
import StoresCarousel from "../components/landing/stores-carousel"
import SaleCarousel from "../components/landing/sale-carousel"
import RecommendedCarousel from "components/landing/recommended-carousel"
import CategoriesCarousel from "components/landing/categories-carousel"
import LeaderboardSection from "components/landing/leaderboard-section"
import Spacer from "components/spacer"
import SearchModal from "components/landing/search-modal"
import SearchButton from "components/landing/search-button"
import { Flex } from "components/layout/shared"
import Header from "components/shared/header"
import SEO from "components/seo"
import FeaturedCategoriesCarousel from "components/landing/featured-categories-carousel"
import { fetcher } from "network"
import useSWR from "swr"
import Head from "next/head"
import * as actions from "context/actionTypes"
import { useGlobalDisptach } from "context/GlobalContextProvider.js"
import SellSection from "components/sell-section"
import LazyLoad from "react-lazyload"
import { getSpecialCategories } from "database/firebase"
import { getLandingPageSnapshot } from "lib/firebase"

const Container = styled.div`
  background: #f7f7f7;
`
const SearchContainer = styled(Flex)`
  margin: 0 auto;
  max-width: 1200px;
  padding: 10px;
  justify-content: flex-end;

  @media (max-width: 550px) {
    justify-content: center;
  }
`

const IndexPage = ({
  landingPageData,
  leaderboardCategories,
  featuredCategories,
}) => {
  const { data: serverData, error } = useSWR(
    `/api/landing/landing-page-data`,
    fetcher
  )
  const dispatch = useGlobalDisptach()

  const { newOnUnqueue, recommendedForYou } = landingPageData || []
  const { categories, onSaleNow, popularStores } = serverData || []

  const [searchModalOpen, setSearchModalOpen] = useState(false)

  useEffect(() => {
    dispatch({
      type: actions.SET_CUSTOM_CHECKOUT,
      item: false,
    })
  }, [])

  const shuffleArray = useCallback(array => {
    const oldArray = [...(array ?? [])]
    for (let i = oldArray.length - 1; i > 0; i--) {
      const j = Math.floor(Math.random() * (i + 1))
      ;[oldArray[i], oldArray[j]] = [oldArray[j], oldArray[i]]
    }
    return oldArray
  }, [])

  return (
    <Container>
      <Header />
      <Head>
        <meta name="apple-itunes-app" content="app-id=1518497712" />
        <link
          rel="stylesheet"
          href="https://use.fontawesome.com/releases/v5.3.1/css/all.css"
        />
      </Head>
      <SEO
        title="Shop"
        description="Choices, details, convenience. Shop food, clothing, plants, electronics, and more – all in one place."
      />
      {searchModalOpen && (
        <SearchModal open={searchModalOpen} setOpen={setSearchModalOpen} />
      )}
      <div
        style={{
          zIndex: 3,
          position: "sticky",
          top: 51,
          background: "white",
          borderBottom: "1px solid #ddd",
        }}
      >
        <SearchContainer
          style={{ margin: "0 auto", maxWidth: 1200, padding: 10 }}
          direction="row"
          align="center"
        >
          <SearchButton clickHandler={() => setSearchModalOpen(true)} />
        </SearchContainer>
      </div>
      <section style={{ background: colors.base }}>
        <LeaderboardSection leaderboardCategories={leaderboardCategories} />
      </section>
      <RecommendedCarousel
        data={shuffleArray(recommendedForYou)}
        title="Show some love."
        useHeader
      />
      <Spacer size={30} />
      <LazyLoad once>
        <StoresCarousel data={newOnUnqueue} title="New On Unqueue" />
        <Spacer size={30} />
        <SaleCarousel data={shuffleArray(onSaleNow)} title="Seasonal Finds" />
        <StoresCarousel
          highlights
          data={shuffleArray(popularStores)}
          title="Highlights"
        />
      </LazyLoad>
      <FeaturedCategoriesCarousel
        featuredCategories={shuffleArray(featuredCategories)}
      />
      {categories && categories.length && (
        <CategoriesCarousel data={categories} title="Shop by Category" />
      )}
      <Spacer size={30} />
      <SellSection />
    </Container>
  )
}

export default IndexPage

export async function getStaticProps() {
  const landingPageSnapshot = await getLandingPageSnapshot().catch(error =>
    console.log("error", error)
  )
  const landingPageData = landingPageSnapshot.data
  delete landingPageData["featuredCategories"]
  delete landingPageData["leaderboardCategories"]
  delete landingPageData["popularStores"]
  delete landingPageData["onSaleNow"]
  delete landingPageData["categories"]
  const specialCategories = await getSpecialCategories().catch(err =>
    console.log("error fetching special categories", err)
  )
  return {
    props: {
      landingPageData: landingPageData ?? [],
      leaderboardCategories: specialCategories?.leaderboardSnapshotData ?? [{}],
      featuredCategories: specialCategories?.featuredSnapshotData ?? [{}],
    },
    revalidate: 15,
  }
}
