import React from "react"
import styled from "styled-components"
import { Button, Typography } from "@unqueue-dev-inc/unqueue-ui-web"
import { colors } from "styles/colors"
import { Flex } from "./layout/shared"
import { motion } from "framer-motion"

const Container = styled.section`
  background: ${colors.base};
`
const Wrapper = styled.div`
  max-width: 1200px;
  margin: 0 auto;
  display: block;
  min-height: 60vh;
  padding: 5vh 20px;

  @media (min-width: 700px) {
    display: grid;
    grid-template-columns: 50% auto;
    align-items: center;
    gap: 10%;
  }
`
const Illustration = styled.img`
  max-width: 400px;
`

export default function SellSection({ full = false }) {
  return (
    <Container full={full} id="sell">
      <Wrapper>
        <motion.div
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          style={{ marginBottom: 30 }}
        >
          <Typography gutter color="white">
            We love local.
          </Typography>
          <Typography
            // style={{ maxWidth: "40ch" }}
            paragraph
            variant="body"
            color="white"
          >
            Unqueue supports local businesses and the people who love them. Get
            in touch if you have questions about our vendors, or if you&apos;d
            like to sign up to sell on our platform – it&apos;s free!
          </Typography>
          <a
            target="_blank"
            rel="noreferrer"
            href="https://dashboard.unqueue.app?ref=shop"
            style={{ textDecoration: "none", color: "white" }}
          >
            <Button outline style={{ color: "white", borderColor: "white" }}>
              Get started
            </Button>
          </a>
        </motion.div>
        <Flex align="center" justify="center">
          <Illustration src="/sell.svg" />
        </Flex>
      </Wrapper>
    </Container>
  )
}
